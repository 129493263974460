import {
  Box, Flex, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue,
} from '@chakra-ui/react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
// Custom components
import * as React from 'react';
import useTranslations from '~/core/hooks/useTranslations';
import EmptyTable, { EmptyTableMethods } from '~/components/emptyTable';

type RowObj = {
  email: string;
  fullname: string;
  createdAt: string;
};

const columnHelper = createColumnHelper<RowObj>();

// const columns = columnsDataCheck;
export default function PropertiesTable( props: { tableData: any, onOpen: any } ) {

  const { t } = useTranslations();

  const { tableData, onOpen } = props;
  const [ sorting, setSorting ] = React.useState<SortingState>( [] );
  const textColor = useColorModeValue( 'secondaryGray.900', 'white' );
  const borderColor = useColorModeValue( 'gray.200', 'whiteAlpha.100' );

  const nameHeader = () => (
    <Text
      justifyContent="space-between"
      align="center"
      fontSize={{ sm: '10px', lg: '12px' }}
      color="gray.400"
      textTransform="uppercase"
    >
      { t( 'users.table.headers.fullname' ) }
    </Text>
  );

  const nameCell = ( info: any ) => (
    <Flex align="center">
      <Text color={textColor} fontSize="sm" fontWeight="700">
        {info.getValue()}
      </Text>
    </Flex>
  );

  const emailHeader = () => (
    <Text
      justifyContent="space-between"
      align="center"
      fontSize={{ sm: '10px', lg: '12px' }}
      color="gray.400"
      textTransform="uppercase"
    >
      { t( 'users.table.headers.email' ) }
    </Text>
  );

  const emailCell = ( info: any ) => (
    <Flex align="center">
      <Text color={textColor} fontSize="sm">
        {info.getValue()}
      </Text>
    </Flex>
  );

  const dateHeader = () => (
    <Text
      justifyContent="space-between"
      align="center"
      fontSize={{ sm: '10px', lg: '12px' }}
      color="gray.400"
      textTransform="uppercase"
    >
      { t( 'users.table.headers.date' ) }
    </Text>
  );

  const dateCell = ( info: any ) => {

    const date = new Date( info.getValue() );
    const formattedDate = date.toLocaleDateString( 'sk-SK', {
      day: 'numeric',
      month: 'long',
    } );
    const formattedTime = date.toLocaleTimeString( 'sk-SK', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    } );

    return (
      <Text color={textColor} fontSize="sm" fontWeight="700">
        {`${formattedDate}, ${formattedTime}`}
      </Text>
    );

  };

  const columns = [
    columnHelper.accessor( 'fullname', {
      id: 'name',
      header: () => (
        nameHeader()
      ),
      cell: ( info ) => (
        nameCell( info )
      ),
    } ),
    columnHelper.accessor( 'email', {
      id: 'email',
      header: () => (
        emailHeader()
      ),
      cell: ( info ) => (
        emailCell( info )
      ),
    } ),
    columnHelper.accessor( 'createdAt', {
      id: 'date',
      header: () => (
        dateHeader()
      ),
      cell: ( info ) => (
        dateCell( info )
      ),
    } ),
  ];
  const [ data, setData ] = React.useState( () => tableData );

  const emptyTableRef = React.useRef< EmptyTableMethods >( null );

  React.useEffect( () => {

    setData( tableData );

  }, [ tableData ] );
  const table = useReactTable( {
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
    onStateChange: () => emptyTableRef.current?.setLoading( false ),
  } );

  return (
    <Box>
      {!table.getRowCount()
        ? (
          <EmptyTable table="users" onOpen={onOpen} ref={emptyTableRef} />
        )
        : (
          <Table variant="simple" color="gray.500" mb="24px">
            <Thead>
              {table.getHeaderGroups().map( ( headerGroup ) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map( ( header ) => (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      pe="10px"
                      borderColor={borderColor}
                      cursor="pointer"
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <Flex
                        justifyContent="space-between"
                        align="center"
                        fontSize={{ sm: '10px', lg: '12px' }}
                        color="gray.400"
                      >
                        {flexRender( header.column.columnDef.header, header.getContext() )}
                        {{
                          asc: '',
                          desc: '',
                        }[header.column.getIsSorted() as string] ?? null}
                      </Flex>
                    </Th>
                  ) )}
                </Tr>
              ) )}
            </Thead>
            <Tbody>
              {table.getRowModel().rows.map( ( row ) => (
                <Tr key={row.id}>
                  {row.getVisibleCells().map( ( cell ) => (
                    <Td
                      key={cell.id}
                      fontSize={{ sm: '14px' }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      borderColor="transparent"
                    >
                      {flexRender( cell.column.columnDef.cell, cell.getContext() )}
                    </Td>
                  ) )}
                </Tr>
              ) )}
            </Tbody>
          </Table>
        )}
    </Box>
  );

}
