import React from 'react';

import {
  Flex, Link, List, ListItem, Text, useColorModeValue,
} from '@chakra-ui/react';
import useTranslations from '~/core/hooks/useTranslations';

export default function Footer() {

  const { t, currentLanguage } = useTranslations();

  const textColor = useColorModeValue( 'gray.400', 'white' );
  const linkColor = useColorModeValue( { base: 'gray.400', lg: 'white' }, 'white' );

  return (
    <Flex
      zIndex="3"
      flexDirection={{
        base: 'column',
        lg: 'row',
      }}
      alignItems={{
        base: 'center',
        xl: 'start',
      }}
      justifyContent="space-between"
      px={{ base: '30px', md: '0px' }}
      pb="30px"
    >
      <Text
        color={textColor}
        textAlign={{
          base: 'center',
          xl: 'start',
        }}
        mb={{ base: '20px', lg: '0px' }}
      >
        {' '}
        &copy;
        {' '}
        {new Date().getFullYear()}
        <Text as="span" fontWeight="500" ms="4px">
          { t( 'footer.auth.copyright' ) }
        </Text>
      </Text>
      <List display="flex">
        <ListItem
          me={{
            base: '20px',
            md: '44px',
          }}
        >
          <Link fontWeight="500" color={linkColor} href="https://montyo.eu">
            { t( 'footer.links.webpage' ) }
          </Link>
        </ListItem>
        <ListItem
          me={{
            base: '20px',
            md: '44px',
          }}
        >
          <Link fontWeight="500" color={linkColor} href="mailto:info@montyo.app">
            { t( 'footer.links.support' ) }
          </Link>
        </ListItem>
        <ListItem
          me={{
            base: '20px',
            md: '44px',
          }}
        >
          <Link fontWeight="500" color={linkColor} href={`https://montyo.eu/${currentLanguage === 'en' ? 'terms' : 'vop'}`}>
            { t( 'footer.links.terms' ) }
          </Link>
        </ListItem>
        <ListItem>
          <Link fontWeight="500" color={linkColor} href={`https://montyo.eu/${currentLanguage === 'en' ? 'privacy' : 'zoou'}`}>
            { t( 'footer.links.privacy' ) }
          </Link>
        </ListItem>
      </List>
    </Flex>
  );

}
