import React, {
  createContext, useContext, ReactNode, useState, useMemo,
} from 'react';
import Translations from '~/core/translations';
import i18n from '~/core/helpers/i18n';

export type LanguageType = keyof typeof Translations;

const TranslationsContext = createContext<{
  setLocale: any;
  t: any;
  currentLanguage: LanguageType;
  langs: LanguageType[];
} | undefined>( undefined );

export const TranslationsProvider: React.FC<{ children: ReactNode }> = ( { children } ) => {

  const [ currentLanguage, setCurrentLanguage ] = useState( i18n.locale as LanguageType );

  const t: typeof i18n.t = ( str, params ) => i18n.t( str, params );

  const langs = Object.keys( Translations ) as LanguageType[];

  const setLocale = ( locale?: LanguageType ) => {

    if ( locale ) {

      localStorage.setItem( 'language', locale );

    } else if ( localStorage.getItem( 'language' ) ) {

      locale = localStorage.getItem( 'language' ) as LanguageType;

    } else {

      locale = navigator.language.substring( 0, 2 ) as LanguageType;

      if ( !Object.keys( Translations ).includes( locale ) ) {

        locale = 'en';

      }

    }

    i18n.locale = locale;
    setCurrentLanguage( locale );

  };

  const cachedValue = useMemo(
    () => ( {
      setLocale, t, currentLanguage, langs,
    } ),
    [ setLocale, t, currentLanguage, langs ],
  );

  return (
    <TranslationsContext.Provider value={cachedValue}>
      {children}
    </TranslationsContext.Provider>
  );

};

export default () => useContext( TranslationsContext );
