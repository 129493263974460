import React, {
  createContext, useContext, useReducer, ReactNode, useMemo,
} from 'react';
import { isEqual } from 'lodash';

type State = {
  user?: any | null;
};

type Action = { type: 'SET_DATA'; payload: State };

const initialState: State = {
  user: null,
};

const GlobalDataContext = createContext<{
  state: State;
  dispatch: React.Dispatch<Action>;
} | undefined>( undefined );

const globalDataReducer = ( state: State, action: Action ): State => {

  switch ( action.type ) {

    case 'SET_DATA':
      // dont redraw if data is the same
      if ( isEqual( state, { ...state, ...action.payload } ) ) {

        return state;

      }

      return { ...state, ...action.payload };
    default:
      return state;

  }

};

export const GlobalDataProvider: React.FC<{ children: ReactNode }> = ( { children } ) => {

  const [ state, dispatch ] = useReducer( globalDataReducer, initialState );

  const cachedValue = useMemo(
    () => ( { state, dispatch } ),
    [ state, dispatch ],
  );

  return (
    <GlobalDataContext.Provider value={cachedValue}>
      {children}
    </GlobalDataContext.Provider>
  );

};

export const useGlobalData = () => {

  const context = useContext( GlobalDataContext );
  if ( !context ) {

    throw new Error( 'useGlobalData must be used within a GlobalDataProvider' );

  }

  return context;

};
