import React from 'react';
import { createRoot } from 'react-dom/client';
import '~/assets/css/App.css';
import {
  HashRouter, Route, Switch, Redirect,
} from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import Cookies from 'js-cookie';
import AuthLayout from '~/layouts/auth';
import AdminLayout from '~/layouts/admin';
import RTLLayout from '~/layouts/rtl';
import theme from '~/theme/theme';
import { GlobalDataProvider } from '~/core/hooks/useGlobalData';
import { TranslationsProvider } from '~/core/hooks/useTranslations';

const App = () => {

  const loggedIn = Cookies.get( 'accessToken' );

  return (
    <ChakraProvider theme={theme}>
      <React.StrictMode>
        <GlobalDataProvider>
          <TranslationsProvider>
            <HashRouter>
              <Switch>
                <Route path="/auth" component={AuthLayout} />
                <Route path="/admin" component={AdminLayout} />
                <Route path="/rtl" component={RTLLayout} />
                <Redirect from="/" to={loggedIn ? '/admin' : '/auth'} />
              </Switch>
            </HashRouter>
          </TranslationsProvider>
        </GlobalDataProvider>
      </React.StrictMode>
    </ChakraProvider>
  );

};

const container = document.getElementById( 'root' );
const root = createRoot( container );
root.render( <App /> );
