/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|

=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included 
in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Box,
  useColorModeValue,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Button,
  Text,
  Heading,
  InputGroup,
  Checkbox,
  FormHelperText,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
} from '@chakra-ui/react';
// Assets
import React from 'react';
// Custom components
import PropertiesTable from '~/views/admin/default/components/PropertiesTable';
import Card from '~/components/card/Card';
import AuthFetch from '~/core/helpers/fetch';
import { API_BASE_URL } from '~/core/config';
import useTranslations from '~/core/hooks/useTranslations';
import { useGlobalData } from '~/core/hooks/useGlobalData';

export default function UserReports() {

  const { t } = useTranslations();
  const [ signEveryFlat, setChecked ] = React.useState( false );
  const [ formErrors, setFormErrors ] = React.useState( [] );
  const [ properties, setProperties ] = React.useState( [] );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { state } = useGlobalData();

  const [ formData, setFormData ] = React.useState( {
    name: '',
    street: '',
  } );

  const getProperties = async () => {

    const propertiesJson = await AuthFetch.request( `${API_BASE_URL}/admin/properties-list`, {
      method: 'GET',
      credentials: 'include',
    } );

    setProperties( propertiesJson );

  };

  const handleFormDataChange = ( field: string, value: string ) => {

    if ( value.trim() === '' && !formErrors.includes( field ) ) {

      setFormErrors( [ ...formErrors, field ] );

    } else {

      setFormErrors( formErrors.filter( ( error ) => error !== field ) );

    }

    setFormData( ( prevState ) => ( {
      ...prevState,
      [field]: value,
    } ) );

  };

  const addProperty = async ( event: any ) => {

    event.preventDefault();

    const errorFields: string[] = [];

    Object.entries( formData ).forEach( ( [ field, value ] ) => {

      if ( value.trim() === '' ) {

        errorFields.push( field );

      }

    } );

    setFormErrors( errorFields );

    if ( errorFields.length ) {

      return;

    }

    await AuthFetch.request( `${API_BASE_URL}/admin/create-property`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify( { ...formData, signEveryFlat } ),
    } );

    setFormData( {
      name: '',
      street: '',
    } );
    setChecked( false );

    getProperties();

    onClose();

  };

  React.useEffect( () => {

    getProperties();

  }, [] );

  React.useEffect( () => {

    if ( state.user?.role === 'super_admin' ) {

      // eslint-disable-next-line no-restricted-globals
      location.href = '/#/admin/users';

    }

  }, [ state ] );
  // Chakra Color Mode
  const textColor = useColorModeValue( 'secondaryGray.900', 'white' );
  const textColorSecondary = 'gray.400';
  const brandStars = useColorModeValue( 'brand.500', 'brand.400' );
  const errorColor = 'red.500';

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Card flexDirection="column" w="100%" px="0px" overflowX={{ sm: 'scroll', lg: 'hidden' }}>
        <Box px="25px">
          <Flex justifyContent="space-between" mb="18px" flexDirection={{ base: 'column', md: 'row' }}>
            <Box>
              <Heading color={textColor} fontSize="36px" mb="10px">
                { t( 'properties.table.heading' ) }
              </Heading>
              <Text
                mb="18px"
                ms="4px"
                color={textColorSecondary}
                fontWeight="400"
                fontSize="md"
              >
                { t( 'properties.table.description' ) }
              </Text>
            </Box>

            <Button
              onClick={onOpen}
              variant="brand"
              fontWeight="500"
              h="48px"
              w="fit-content"
              ms={{ base: '0', md: '32px' }}
            >
              { t( 'properties.new.new' ) }
            </Button>
          </Flex>
        </Box>
        <PropertiesTable tableData={properties} onOpen={onOpen} />
      </Card>

      <Modal isCentered isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color={textColor} fontSize="36px" mb="10px">
            <Heading color={textColor} fontSize="36px" mb="10px">
              { t( 'properties.new.heading' ) }
            </Heading>
            <Text
              ms="4px"
              color={textColorSecondary}
              fontWeight="400"
              fontSize="md"
            >
              { t( 'properties.new.description' ) }
            </Text>
          </ModalHeader>
          <ModalBody>
            <Flex
              direction="column"
              w={{ base: '100%' }}
              maxW="100%"
              background="transparent"
              borderRadius="15px"
              mx={{ base: 'auto', lg: 'unset' }}
              me="auto"
              mb={{ base: '20px', md: 'auto' }}
            >
              <FormControl>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  { t( 'properties.new.name.label' ) }
                  <Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                  value={formData.name}
                  isRequired
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: '0px', md: '0px' }}
                  type="email"
                  placeholder={t( 'properties.new.name.placeholder' )}
                  fontWeight="500"
                  size="lg"
                  borderColor={formErrors.includes( 'name' ) ? errorColor : undefined}
                  onChange={( e ) => handleFormDataChange( 'name', e.target.value )}
                />
                {formErrors.includes( 'name' ) && (
                <FormHelperText ms="4px" color={errorColor}>
                  { t( 'error', { label: t( 'properties.new.name.label' ) } ) }
                </FormHelperText>
                )}
                <FormLabel
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  display="flex"
                  mt="24px"
                >
                  { t( 'properties.new.street.label' ) }
                  <Text color={brandStars}>*</Text>
                </FormLabel>
                <InputGroup size="md">
                  <Input
                    value={formData.street}
                    isRequired
                    fontSize="sm"
                    placeholder={t( 'properties.new.street.placeholder' )}
                    size="lg"
                    type="text"
                    variant="auth"
                    borderColor={formErrors.includes( 'street' ) ? errorColor : undefined}
                    onChange={( e ) => handleFormDataChange( 'street', e.target.value )}
                  />
                </InputGroup>
                {formErrors.includes( 'street' ) && (
                <FormHelperText ms="4px" color={errorColor}>
                  { t( 'error', { label: t( 'properties.new.street.label' ) } ) }
                </FormHelperText>
                )}
                <Flex justifyContent="space-between" align="center" mt="24px">
                  <FormControl display="flex" alignItems="center">
                    <Checkbox
                      id="remember-login"
                      colorScheme="brandScheme"
                      me="10px"
                      size="lg"
                      isChecked={signEveryFlat}
                      onChange={( e ) => setChecked( e.target.checked )}
                    />
                    <FormLabel
                      htmlFor="remember-login"
                      mb="0"
                      fontWeight="normal"
                      color={textColor}
                      fontSize="sm"
                    >
                      { t( 'properties.new.checkbox.label' ) }
                    </FormLabel>
                  </FormControl>
                </Flex>
              </FormControl>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={onClose}
              fontSize="sm"
              fontWeight="500"
              h="48px"
              mr="6px"
            >
              { t( 'properties.new.close' ) }
            </Button>
            <Button
              onClick={addProperty}
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              h="48px"
            >
              { t( 'properties.new.submit' ) }
            </Button>

          </ModalFooter>
        </ModalContent>
      </Modal>
      {/* <SimpleGrid
        columns={{
          base: 1, md: 2, lg: 3, '2xl': 6,
        }}
        gap="20px"
        mb="20px"
      >
        <MiniStatistics
          startContent={(
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={<Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />}
            />
    )}
          name="Earnings"
          value="$350.4"
        />
        <MiniStatistics
          startContent={(
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={<Icon w="32px" h="32px" as={MdAttachMoney} color={brandColor} />}
            />
    )}
          name="Spend this month"
          value="$642.39"
        />
        <MiniStatistics growth="+23%" name="Sales" value="$574.34" />
        <MiniStatistics
          endContent={(
            <Flex me="-16px" mt="10px">
              <FormLabel htmlFor="balance">
                <Avatar src={Usa} />
              </FormLabel>
              <Select id="balance" variant="mini" mt="5px" me="0px" defaultValue="usd">
                <option value="usd">USD</option>
                <option value="eur">EUR</option>
                <option value="gba">GBA</option>
              </Select>
            </Flex>
    )}
          name="Your balance"
          value="$1,000"
        />
        <MiniStatistics
          startContent={(
            <IconBox
              w="56px"
              h="56px"
              bg="linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)"
              icon={<Icon w="28px" h="28px" as={MdAddTask} color="white" />}
            />
    )}
          name="New Tasks"
          value="154"
        />
        <MiniStatistics
          startContent={(
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={<Icon w="32px" h="32px" as={MdFileCopy} color={brandColor} />}
            />
    )}
          name="Total Projects"
          value="2935"
        />
      </SimpleGrid> */}

      {/* <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
        <TotalSpent />
        <WeeklyRevenue />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap="20px" mb="20px">
        <CheckTable tableData={tableDataCheck} />
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px">
          <DailyTraffic />
          <PieCard />
        </SimpleGrid>
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap="20px" mb="20px">
        <ComplexTable tableData={tableDataComplex} />
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px">
          <Tasks />
          <MiniCalendar h="100%" minW="100%" selectRange={false} />
        </SimpleGrid>
      </SimpleGrid> */}
    </Box>
  );

}
