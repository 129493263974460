/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|

=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included 
in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Box,
  useColorModeValue,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputRightElement,
  Icon,
  Button,
  Text,
  Heading,
  InputGroup,
  FormHelperText,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react';
// Assets
import React from 'react';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
// Custom components
import PropertiesTable from '~/views/admin/users/components/PropertiesTable';
import Card from '~/components/card/Card';
import AuthFetch from '~/core/helpers/fetch';
import { API_BASE_URL } from '~/core/config';
import { validateEmail } from '~/core/helpers/string';
import useTranslations from '~/core/hooks/useTranslations';
import { useGlobalData } from '~/core/hooks/useGlobalData';

export default function UserReports() {

  const { t } = useTranslations();
  const { state } = useGlobalData();

  const format = ( val: string ) => `€${val}`;
  const parse = ( val: string ) => val.replace( /^€/, '' );

  const [ formData, setFormData ] = React.useState( {
    email: '',
    password: '',
    fullname: '',
    pricePerDevice: '0.5',
  } );

  const [ formErrors, setFormErrors ] = React.useState( [] );
  const [ users, setUsers ] = React.useState( [] );
  const [ show, setShow ] = React.useState( false );
  const handleClick = () => setShow( !show );

  const { isOpen, onOpen, onClose } = useDisclosure();

  const getUsers = async () => {

    const usersJson = await AuthFetch.request( `${API_BASE_URL}/admin/users-list`, {
      method: 'GET',
      credentials: 'include',
    } );

    setUsers( usersJson );

  };

  const handleFormDataChange = ( field: string, value: string ) => {

    let errorFields = [];

    if ( value.trim() === '' && !formErrors.includes( field ) ) {

      errorFields = [ ...formErrors, field ];

    } else {

      errorFields = formErrors.filter( ( error ) => error !== field );

    }

    if ( field === 'email' ) {

      errorFields = errorFields.filter( ( error ) => error !== 'invalidEmail' );

    }

    setFormErrors( errorFields );

    setFormData( ( prevState ) => ( {
      ...prevState,
      [field]: value,
    } ) );

  };

  const addUser = async ( event: any ) => {

    event.preventDefault();

    const errorFields: string[] = [];

    let body: { [key: string]: string } = formData;
    if ( state?.user?.role !== 'super_admin' ) {

      body = { email: formData.email, fullname: formData.fullname };

    }

    Object.entries( body ).forEach( ( [ field, value ] ) => {

      if ( value.trim() === '' ) {

        errorFields.push( field );

      } else if ( field === 'email' && !validateEmail( value ) ) {

        errorFields.push( 'invalidEmail' );

      }

    } );

    setFormErrors( errorFields );

    if ( errorFields.length ) {

      return;

    }

    await AuthFetch.request( `${API_BASE_URL}/admin/create${( state?.user?.role === 'super_admin' ? '-admin' : '' )}-user`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify( body ),
    } );

    setFormData( {
      email: '',
      password: '',
      fullname: '',
      pricePerDevice: '0.5',
    } );

    getUsers();

    onClose();

  };

  React.useEffect( () => {

    getUsers();

  }, [] );

  // Chakra Color Mode
  const textColor = useColorModeValue( 'secondaryGray.900', 'white' );
  const textColorSecondary = 'gray.400';
  const brandStars = useColorModeValue( 'brand.500', 'brand.400' );
  const errorColor = 'red.500';

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      {/* Content Area */}
      <Card flexDirection="column" w="100%" px="0px" overflowX={{ sm: 'scroll', lg: 'hidden' }}>
        <Box px="25px">
          <Flex justifyContent="space-between" mb="18px" flexDirection={{ base: 'column', md: 'row' }}>
            <Box>
              <Heading color={textColor} fontSize="36px" mb="10px">
                { t( 'users.table.heading' ) }
              </Heading>
              <Text
                mb="18px"
                ms="4px"
                color={textColorSecondary}
                fontWeight="400"
                fontSize="md"
              >
                { t( 'users.table.description' ) }
              </Text>
            </Box>

            <Button
              onClick={onOpen}
              variant="brand"
              fontWeight="500"
              h="48px"
              w="fit-content"
              ms={{ base: '0', md: '32px' }}
            >
              { t( 'users.new.new' ) }
            </Button>
          </Flex>

        </Box>
        <PropertiesTable tableData={users} onOpen={onOpen} />
      </Card>

      <Modal isCentered isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color={textColor} fontSize="36px" mb="10px">
            <Heading color={textColor} fontSize="36px" mb="10px">
              { t( 'users.new.heading' ) }
            </Heading>
            <Text
              ms="4px"
              color={textColorSecondary}
              fontWeight="400"
              fontSize="md"
            >
              { t( 'users.new.description' ) }
            </Text>
          </ModalHeader>
          <ModalBody>
            <Flex
              direction="column"
              w={{ base: '100%' }}
              maxW="100%"
              background="transparent"
              borderRadius="15px"
              mx={{ base: 'auto', lg: 'unset' }}
              me="auto"
              mb={{ base: '20px', md: 'auto' }}
            >
              <FormControl>
                <FormLabel
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  display="flex"
                  mb="8px"
                >
                  { t( 'users.new.fullname.label' ) }
                  <Text color={brandStars}>*</Text>
                </FormLabel>
                <InputGroup size="md">
                  <Input
                    value={formData.fullname}
                    isRequired
                    fontSize="sm"
                    placeholder={t( 'users.new.fullname.placeholder' )}
                    size="lg"
                    type="text"
                    variant="auth"
                    borderColor={formErrors.includes( 'fullname' ) ? errorColor : undefined}
                    onChange={( e ) => handleFormDataChange( 'fullname', e.target.value )}
                  />
                </InputGroup>
                {formErrors.includes( 'fullname' ) && (
                  <FormHelperText ms="4px" color={errorColor}>
                    { t( 'error', { label: t( 'users.new.fullname.label' ) } ) }
                  </FormHelperText>
                )}
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mt="24px"

                >
                  { t( 'users.new.email.label' ) }
                  <Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                  value={formData.email}
                  isRequired
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: '0px', md: '0px' }}
                  type="email"
                  placeholder={t( 'users.new.email.placeholder' )}
                  fontWeight="500"
                  size="lg"
                  borderColor={formErrors.includes( 'email' ) || formErrors.includes( 'invalidEmail' ) ? errorColor : undefined}
                  onChange={( e ) => handleFormDataChange( 'email', e.target.value )}
                />
                {formErrors.includes( 'email' ) && (
                  <FormHelperText ms="4px" color={errorColor}>
                    { t( 'error', { label: t( 'users.new.email.label' ) } ) }
                  </FormHelperText>
                )}
                {formErrors.includes( 'invalidEmail' ) && (
                  <FormHelperText ms="4px" color={errorColor}>
                    { t( 'invalidError', { label: t( 'users.new.email.label' ) } ) }
                  </FormHelperText>
                )}
                { state?.user?.role === 'super_admin' && (
                <>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mt="24px"
                  >
                    { t( 'users.new.password.label' ) }
                    <Text color={brandStars}>*</Text>
                  </FormLabel>
                  <InputGroup>
                    <Input
                      value={formData.password}
                      isRequired
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: '0px', md: '0px' }}
                      type={show ? 'text' : 'password'}
                      placeholder={t( 'users.new.password.placeholder' )}
                      fontWeight="500"
                      size="lg"
                      borderColor={formErrors.includes( 'password' ) ? errorColor : undefined}
                      onChange={( e ) => handleFormDataChange( 'password', e.target.value )}
                    />
                    <InputRightElement display="flex" alignItems="center" mt="4px">
                      <Icon
                        color={textColorSecondary}
                        _hover={{ cursor: 'pointer' }}
                        as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                        onClick={handleClick}
                      />
                    </InputRightElement>
                  </InputGroup>
                  {formErrors.includes( 'password' ) && (
                  <FormHelperText ms="4px" color={errorColor}>
                    { t( 'error', { label: t( 'users.new.password.label' ) } ) }
                  </FormHelperText>
                  )}
                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                    mt="24px"
                  >
                    { t( 'users.new.price.label' ) }
                    <Text color={brandStars}>*</Text>
                  </FormLabel>
                  <NumberInput
                    onChange={( valueString ) => handleFormDataChange( 'pricePerDevice', parse( valueString ) )}
                    value={format( formData.pricePerDevice )}
                    fontSize="sm"
                    size="lg"
                    variant="auth"
                    borderColor={formErrors.includes( 'pricePerDevice' ) ? errorColor : undefined}
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  {formErrors.includes( 'pricePerDevice' ) && (
                  <FormHelperText ms="4px" color={errorColor}>
                    { t( 'error', { label: t( 'users.new.price.label' ) } ) }
                  </FormHelperText>
                  )}
                </>
                )}
              </FormControl>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={onClose}
              fontSize="sm"
              fontWeight="500"
              h="48px"
              mr="6px"
            >
              { t( 'users.new.close' ) }
            </Button>
            <Button
              onClick={addUser}
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              h="48px"
            >
              { t( 'users.new.submit' ) }
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/* <SimpleGrid columns={{ base: 1, md: 2, lg: 3, '2xl': 6 }} gap='20px' mb='20px'>
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={<Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />}
            />
          }
          name='Earnings'
          value='$350.4'
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={<Icon w='32px' h='32px' as={MdAttachMoney} color={brandColor} />}
            />
          }
          name='Spend this month'
          value='$642.39'
        />
        <MiniStatistics growth='+23%' name='Sales' value='$574.34' />
        <MiniStatistics
          endContent={
            <Flex me='-16px' mt='10px'>
              <FormLabel htmlFor='balance'>
                <Avatar src={Usa} />
              </FormLabel>
              <Select id='balance' variant='mini' mt='5px' me='0px' defaultValue='usd'>
                <option value='usd'>USD</option>
                <option value='eur'>EUR</option>
                <option value='gba'>GBA</option>
              </Select>
            </Flex>
          }
          name='Your balance'
          value='$1,000'
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg='linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)'
              icon={<Icon w='28px' h='28px' as={MdAddTask} color='white' />}
            />
          }
          name='New Tasks'
          value='154'
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={<Icon w='32px' h='32px' as={MdFileCopy} color={brandColor} />}
            />
          }
          name='Total Projects'
          value='2935'
        />
      </SimpleGrid> */}

      {/* <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
        <TotalSpent />
        <WeeklyRevenue />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
        <CheckTable tableData={tableDataCheck} />
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
          <DailyTraffic />
          <PieCard />
        </SimpleGrid>
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
        <ComplexTable tableData={tableDataComplex} />
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
          <Tasks />
          <MiniCalendar h='100%' minW='100%' selectRange={false} />
        </SimpleGrid>
      </SimpleGrid> */}
    </Box>
  );

}
