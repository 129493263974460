import React, { useEffect, useState } from 'react';
import {
  FormControl, FormLabel, Text, useColorModeValue,
} from '@chakra-ui/react';
import { Select, ChakraStylesConfig } from 'chakra-react-select';

import useTranslations from '~/core/hooks/useTranslations';

const CountrySelector = ( {
  formData,
  formErrors,
  errorColor,
  textColor,
  brandStars,
  handleFormDataChange,
}: any ) => {

  const { t, currentLanguage } = useTranslations();

  const [ countries, setCountries ] = useState( [] );
  const [ isLoading, setIsLoading ] = useState( true );

  useEffect( () => {

    const fetchCountries = async () => {

      try {

        const response = await fetch( 'https://restcountries.com/v3.1/region/europe' );
        const data = await response.json();

        let countryOptions;

        if ( currentLanguage === 'en' ) {

          countryOptions = data.map( ( country: any ) => ( {
            label: country.name.common,
            value: country.name.common,
          } ) );

        } else {

          countryOptions = data.map( ( country: any ) => ( {
            label: country.translations[t( 'countryCode' )].common,
            value: country.name.common,
          } ) );

        }

        countryOptions = countryOptions
          .sort( ( a: any, b: any ) => a.label.localeCompare( b.label ) );

        setCountries( countryOptions );
        setIsLoading( false );

      } catch ( error ) {

        console.error( 'Error fetching countries:', error );

      }

    };

    fetchCountries();

  }, [] );

  const chakraStyles: ChakraStylesConfig = {
    container: ( provided: any ) => ( {
      ...provided,
      borderColor: formErrors.includes( 'country' ) ? errorColor : undefined,
    } ),
    control: ( provided: any ) => ( {
      ...provided,
      h: '48px',
      borderRadius: '16px',
      fontSize: 'sm',
      fontWeight: '500',
      leterSpacing: 'normal',
      _focus: {
        borderColor: 'none',
      },
      _hover: {
        borderColor: formErrors.includes( 'country' ) ? errorColor : undefined,
      },
    } ),
    placeholder: ( provided: any ) => ( {
      ...provided,
      color: 'secondaryGray.600',
      fontWeight: '400',
    } ),
    input: ( provided: any ) => ( {
      ...provided,
      color: useColorModeValue( 'navy.700', 'white' ),
    } ),
    dropdownIndicator: ( provided: any ) => ( {
      ...provided,
      fontSize: 'lg',
      cursor: 'pointer',
    } ),
    menuList: ( provided: any ) => ( {
      ...provided,
      borderRadius: '16px',
    } ),
    option: ( provided: any ) => ( {
      ...provided,
      fontSize: 'sm',
      _selected: {
        backgroundColor: 'none',
      },
    } ),
  };

  return (
    <FormControl>
      <FormLabel
        ms="4px"
        fontSize="sm"
        fontWeight="500"
        color={textColor}
        display="flex"
        mt="24px"
      >
        { t( 'address.country.label' ) }
        <Text color={brandStars}>*</Text>
      </FormLabel>
      <Select
        id="country"
        placeholder={t( 'address.country.placeholder' )}
        options={countries}
        isLoading={isLoading}
        chakraStyles={chakraStyles}
        focusBorderColor="none"
        value={countries.find( ( country: any ) => country.value === formData.country )}
        isRequired
        onChange={( e: any ) => handleFormDataChange( 'country', e.value )}
      />
    </FormControl>
  );

};

export default CountrySelector;
