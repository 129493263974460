export default {
  countryCode: 'slk',
  lang: {
    heading: 'Jazyk',
    langs: {
      en: 'Angličtina',
      sk: 'Slovenčina',
    },
  },
  routes: {
    properties: 'Nehnuteľnosti',
    payment: 'Platba',
    users: 'Používatelia',
    company: 'Spoločnosť',
    certifiedPerson: 'Certifikovaná osoba',
    invoices: 'Faktúry',
    signIn: 'Prihlásiť sa',
    signUp: 'Zaregistrovať sa',
    pages: 'Stránky',
  },
  auth: {
    email: {
      label: 'E-mail',
      placeholder: 'tvoj@email.com',
    },
    password: {
      label: 'Heslo',
      placeholder: 'Min. 8 znakov',
    },
    checkbox: {
      label: 'Ostať prihlásený',
    },
    submit: 'Prihlásiť sa',
    signIn: {
      heading: 'Prihlásiť sa',
      description: 'Zadajte svoj e-mail a heslo a prihláste sa!',
      notRegistered: 'Ešte nie ste zaregistrovaný?',
      createAccount: 'Vytvoriť konto',
      back: 'Späť na hlavnú webovú stránku',
    },
    signUp: {
      heading: 'Zaregistrujte si nové konto',
      description: 'Zadajte svoj e-mail a heslo a zaregistrujte sa!',
      back: 'Späť na prihlásenie',
    },
  },
  properties: {
    new: {
      heading: 'Vytvorenie novej nehnuteľnosti',
      description: 'Zadajte meno a ulicu a vytvorte novú!',
      name: {
        label: 'Názov',
        placeholder: 'Vaša nová nehnuteľnosť',
      },
      street: {
        label: 'Ulica',
        placeholder: 'Ulica 123, 00000 Spojené štáty americké',
      },
      checkbox: {
        label: 'Potrebujem podpísať každý byt namiesto podpisu jednej nehnuteľnosti',
      },
      new: 'Nová nehnuteľnosť',
      close: 'Zavrieť',
      submit: 'Vytvoriť nehnuteľnosť',
    },
    table: {
      heading: 'Zoznam vašich nehnuteľností',
      description: 'Kliknutím na nehnuteľnosť získate viac informácií!',
      headers: {
        name: 'Názov',
        street: 'Ulica',
        date: 'Dátum',
      },
      empty: {
        heading: 'Nič tu nie je',
        description: 'Začnite pridávať nehnuteľnosti a prideľte prístup používateľom',
        button: 'Poďme vytvoriť nehnuteľnosť!',
      },
    },
  },
  property: {
    totalPrice: 'Celková cena',
    pricePerDevice: 'Cena za zariadenie',
    installedDevices: 'Nainštalované zariadenia',
    flats: 'Byty',
    export: {
      heading: 'Exportovať dokumenty',
      action: 'Export',
      pending: 'Počkajte, prosím, kým spracujeme všetky dokumenty',
      download: {
        excel: 'Stiahnutie Excel všetkých zariadení',
        zip: 'Stiahnite si .zip všetkých bytov',
      },
    },
    usersTable: {
      fullname: 'Celé meno',
      email: 'E-mail',
      assign: 'Priradiť',
      remove: 'Odstrániť',
      available: {
        heading: 'Dostupní používatelia',
        description: 'Priradenie používateľov k tejto nehnuteľnosti',
      },
      assigned: {
        heading: 'Priradení používatelia',
        description: 'Používatelia môžu do tejto nehnuteľnosti pridávať byty a zariadenia',
      },
    },
    new: {
      heading: 'Vytvorenie nového bytu v %{name}',
      description: 'Zadajte meno a vytvorte nový!',
      name: {
        label: 'Názov',
        placeholder: 'Váš nový byt',
      },
      OM: {
        label: 'OM',
        placeholder: 'OM',
      },
      MP: {
        label: 'MP',
        placeholder: 'MP',
      },
      new: 'Nový byt',
      close: 'Zavrieť',
      submit: 'Vytvoriť byt',
    },
    table: {
      heading: 'Byty v tejto nehnuteľnosti',
      description: 'Môžete exportovať, keď budú všetky byty uzatvorené',
      headers: {
        name: 'Názov',
        closed: 'Uzavreté',
        created: 'Vytvorené',
      },
      empty: {
        heading: 'Nič tu nie je',
        description: 'Začnite pridávať byty v tejto nehnuteľnosti',
        button: 'Poďme vytvoriť byt!',
      },
    },
    license: {
      payForExport: 'Zaplatiť za export nehnuteľnosti',
      payForLicence: 'Najprv musíte zaplatiť za licenciu',
      pricePerDevice: 'Jedno vymenené zariadenie vo vašom byte vás bude stáť %{pricePerDevice} €.',
      devicesCount: 'Máte %{devicesCount} zariadení.',
      buyLicence: 'Licenciu si môžete kúpiť na 1 rok.',
      cancel: 'Zrušiť',
      pay: 'Platba',
    },
    noCertifiedPerson: {
      title: 'Nemáte nastavenú certifikovanú osobu',
      description: 'Nastavte certifikovanú osobu v nastaveniach',
    },
  },
  users: {
    new: {
      heading: 'Vytvorenie nového používateľa',
      description: 'Zadajte e-mail a celé meno pre vytvorenie nového!',
      email: {
        label: 'E-mail',
        placeholder: 'tvoj@email.com',
      },
      fullname: {
        label: 'Celé meno / názov spoločnosti',
        placeholder: 'Ján Novák',
      },
      password: {
        label: 'Heslo',
        placeholder: 'Heslo',
      },
      price: {
        label: 'Cena za 1 montážny protocol',
      },
      new: 'Nový používateľ',
      close: 'Zavrieť',
      submit: 'Vytvoriť používateľa',
    },
    table: {
      heading: 'Zoznam vašich používateľov',
      description: 'Každému používateľovi je potrebné priradiť nehnuteľnosť individuálne!',
      headers: {
        fullname: 'Celé meno',
        email: 'E-mail',
        date: 'Dátum',
      },
      empty: {
        heading: 'Nič tu nie je',
        description: 'Začnite pridávať používateľov a prideľte im prístup k nehnuteľnosti',
        button: 'Poďme vytvoriť používateľa!',
      },
    },
  },
  company: {
    heading: 'Informácie o spoločnosti',
    description: 'Nastavte správne informácie, ktoré sa zobrazia vo faktúre',
    submit: 'Aktualizácia spoločnosti',
  },
  certifiedPerson: {
    info: {
      heading: 'Adresa',
      description: 'Nastavte správne informácie, ktoré sa zobrazia v exporte',
      submit: 'Aktualizácia adresy',
    },
    upload: {
      heading: 'Nahrajte svoju pečiatku',
      description: 'Vaša pečiatka sa nahrá automaticky',
      upload: 'Odoslanie súboru',
      onlyPng: 'povolený je iba súbor PNG',
    },
  },
  invoices: {
    table: {
      heading: 'Zoznam faktúr',
      description: 'Môžete si ich stiahnuť vo formáte PDF',
      headers: {
        download: 'Stiahnúť',
        invoiceNo: 'Číslo faktúry',
        description: 'Popis',
        created: 'Vytvorené',
        price: 'Cena',
        status: 'Stav',
      },
      paid: 'Zaplatená',
      unpaid: 'Nezaplatená',
      empty: {
        heading: 'Nič tu nie je',
        description: 'Tu budú uvedené vaše faktúry',
      },
    },
  },
  address: {
    name: {
      label: 'Názov spoločnosti',
      placeholder: 'DS Corporation, s.r.o.',
    },
    city: {
      label: 'Mesto',
      placeholder: 'Barcelona',
    },
    street: {
      label: 'Ulica',
      placeholder: 'Carrer de la Marina, 16',
    },
    postalCode: {
      label: 'Poštové smerovacie číslo',
      placeholder: '08005',
    },
    country: {
      label: 'Krajina',
      placeholder: 'Vyber krajinu',
    },
    vatNo: {
      label: 'Číslo dane z pridanej hodnoty',
      placeholder: 'SK1234567890',
    },
    description: {
      label: 'Popis',
      placeholder: 'Číslo povolenia: R/I - 066/2022',
    },
  },
  footer: {
    admin: {
      copyright: 'DS Corporation, s.r.o. MONTYO dashboard. Všetky práva vyhradené. Vyrobené s láskou.',
    },
    auth: {
      copyright: 'DS Corporation, s.r.o. Všetky práva vyhradené. Vyrobené s láskou.',
    },
    links: {
      webpage: 'Web',
      support: 'Podpora',
      terms: 'Podmienky',
      privacy: 'Zásady ochrany',
    },
  },
  error: 'Pole %{label} je povinné',
  invalidError: '%{label} je neplatný',
};
