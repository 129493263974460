import {
  Box, Flex, Icon, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, Badge,
} from '@chakra-ui/react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
// Custom components
import * as React from 'react';
import { MdDownload } from 'react-icons/md';
import useTranslations from '~/core/hooks/useTranslations';
import EmptyTable, { EmptyTableMethods } from '~/components/emptyTable';

type RowObj = {
  link: string;
  number: string;
  description: string;
  createdAt: string;
  price: string;
  status: boolean;
};

const columnHelper = createColumnHelper<RowObj>();

// const columns = columnsDataCheck;
export default function PropertiesTable( props: { tableData: any } ) {

  const { t } = useTranslations();

  const { tableData } = props;
  const [ sorting, setSorting ] = React.useState<SortingState>( [] );
  const textColor = useColorModeValue( 'secondaryGray.900', 'white' );
  const borderColor = useColorModeValue( 'gray.200', 'whiteAlpha.100' );

  const linkHeader = () => (
    <Text
      textAlign="end"
      width="100%"
      fontSize={{ sm: '10px', lg: '12px' }}
      color="gray.400"
      pe="14px"
    >
      { t( 'invoices.table.headers.download' ) }
    </Text>
  );

  const linkCell = ( info: any ) => (
    <Text
      textAlign="end"
      width="100%"
    >
      <a href={info.getValue()} download="invoice.pdf" aria-label={t( 'invoices.table.headers.download' )}>
        <Icon
          w="24px"
          h="24px"
          color="green.500"
          as={MdDownload}
        />
      </a>
    </Text>
  );

  const numberHeader = () => (
    <Text
      fontSize={{ sm: '10px', lg: '12px' }}
      color="gray.400"
    >
      { t( 'invoices.table.headers.invoiceNo' ) }
    </Text>
  );

  const numberCell = ( info: any ) => (
    <Flex align="center">
      <Text color={textColor} fontSize="sm">
        {info.getValue()}
      </Text>
    </Flex>
  );

  const descriptionHeader = () => (
    <Text
      fontSize={{ sm: '10px', lg: '12px' }}
      color="gray.400"
    >
      { t( 'invoices.table.headers.description' ) }
    </Text>
  );

  const descriptionCell = ( info: any ) => (
    <Flex align="center">
      <Text color={textColor} fontSize="sm">
        {info.getValue()}
      </Text>
    </Flex>
  );

  const dateHeader = () => (
    <Text
      fontSize={{ sm: '10px', lg: '12px' }}
      color="gray.400"
    >
      { t( 'invoices.table.headers.created' ) }
    </Text>
  );

  const dateCell = ( info: any ) => {

    const date = new Date( info.getValue() );
    const formattedDate = date.toLocaleDateString( 'sk-SK', {
      day: 'numeric',
      month: 'long',
    } );
    const formattedTime = date.toLocaleTimeString( 'sk-SK', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    } );

    return (
      <Text color={textColor} fontSize="sm" fontWeight="700">
        {`${formattedDate}, ${formattedTime}`}
      </Text>
    );

  };

  const priceHeader = () => (
    <Text
      fontSize={{ sm: '10px', lg: '12px' }}
      color="gray.400"
    >
      { t( 'invoices.table.headers.price' ) }
    </Text>
  );

  const priceCell = ( info: any ) => (
    <Flex align="center">
      <Text color={textColor} fontSize="sm">
        {info.getValue()}
      </Text>
    </Flex>
  );

  const statusHeader = () => (
    <Text
      fontSize={{ sm: '10px', lg: '12px' }}
      color="gray.400"
    >
      { t( 'invoices.table.headers.status' ) }
    </Text>
  );

  const statusCell = ( info: any ) => (
    <Flex align="center">
      <Text color={textColor} fontSize="sm">
        <Badge colorScheme={info.getValue() ? 'green' : 'red'}>{info.getValue() ? t( 'invoices.table.paid' ) : t( 'invoices.table.unpaid' )}</Badge>
      </Text>
    </Flex>
  );

  const columns = [
    columnHelper.accessor( 'createdAt', {
      id: 'date',
      header: () => (
        dateHeader()
      ),
      cell: ( info ) => (
        dateCell( info )
      ),
    } ),
    columnHelper.accessor( 'number', {
      id: 'number',
      header: () => (
        numberHeader()
      ),
      cell: ( info ) => (
        numberCell( info )
      ),
    } ),
    columnHelper.accessor( 'description', {
      id: 'description',
      header: () => (
        descriptionHeader()
      ),
      cell: ( info ) => (
        descriptionCell( info )
      ),
    } ),
    columnHelper.accessor( 'price', {
      id: 'price',
      header: () => (
        priceHeader()
      ),
      cell: ( info ) => (
        priceCell( info )
      ),
    } ),
    columnHelper.accessor( 'status', {
      id: 'status',
      header: () => (
        statusHeader()
      ),
      cell: ( info ) => (
        statusCell( info )
      ),
    } ),
    columnHelper.accessor( 'link', {
      id: 'link',
      header: () => (
        linkHeader()
      ),
      cell: ( info ) => (
        linkCell( info )
      ),
    } ),
  ];
  const [ data, setData ] = React.useState( () => tableData );

  const emptyTableRef = React.useRef< EmptyTableMethods >( null );

  React.useEffect( () => {

    setData( tableData );

  }, [ tableData ] );
  const table = useReactTable( {
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
    onStateChange: () => emptyTableRef.current?.setLoading( false ),
  } );

  return (
    <Box>
      {!table.getRowCount()
        ? (
          <EmptyTable table="invoices" ref={emptyTableRef} />
        )
        : (
          <Table variant="simple" color="gray.500" mb="24px">
            <Thead>
              {table.getHeaderGroups().map( ( headerGroup ) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map( ( header ) => (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      pe="10px"
                      borderColor={borderColor}
                      cursor="pointer"
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <Flex
                        justifyContent="space-between"
                        align="center"
                        fontSize={{ sm: '10px', lg: '12px' }}
                        color="gray.400"
                      >
                        {flexRender( header.column.columnDef.header, header.getContext() )}
                        {{
                          asc: '',
                          desc: '',
                        }[header.column.getIsSorted() as string] ?? null}
                      </Flex>
                    </Th>
                  ) )}
                </Tr>
              ) )}
            </Thead>
            <Tbody>
              {table.getRowModel().rows.map( ( row ) => (
                <Tr key={row.id}>
                  {row.getVisibleCells().map( ( cell ) => (
                    <Td
                      key={cell.id}
                      fontSize={{ sm: '14px' }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      borderColor="transparent"
                    >
                      {flexRender( cell.column.columnDef.cell, cell.getContext() )}
                    </Td>
                  ) )}
                </Tr>
              ) )}
            </Tbody>
          </Table>
        )}
    </Box>
  );

}
