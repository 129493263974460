// chakra imports
import { Box, Flex, Stack } from '@chakra-ui/react';
// Assets
import React from 'react';
// Custom components
import Brand from '~/components/sidebar/components/Brand';
import Links from '~/components/sidebar/components/Links';
import LanguageSelector from '~/components/sidebar/components/LanguageSelector';
// import SidebarCard from '~/components/sidebar/components/SidebarCard';

// FUNCTIONS

function SidebarContent( props: { routes: RoutesType[] } ) {

  const { routes } = props;
  // SIDEBAR

  return (
    <Flex direction="column" height="100%" pt="25px" borderRadius="30px">
      <Brand />
      <Stack direction="column" my="8px" height="100%">
        <Box ps="20px" pe={{ lg: '16px', '2xl': '16px' }} display="flex" flexDirection="column" height="100%">
          <Links routes={routes} />
          <LanguageSelector />
        </Box>
      </Stack>
    </Flex>
  );

}

export default SidebarContent;
