// Chakra imports
import { Flex, useColorModeValue } from '@chakra-ui/react';
// Assets
import React from 'react';
// Custom components
import { AquaLogo } from '~/components/icons/Icons';
import { HSeparator } from '~/components/separator/Separator';

export function SidebarBrand() {

  //   Chakra color mode
  const logoColor = useColorModeValue( 'brand.500', 'white' );

  return (
    <Flex alignItems="center" flexDirection="column">
      <AquaLogo h="26px" w="175px" my="32px" color={logoColor} />
      <HSeparator mb="20px" />
    </Flex>
  );

}

export default SidebarBrand;
