/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|

 =========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included 
in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Box, Heading, Flex, Text, useColorModeValue,
} from '@chakra-ui/react';
import React from 'react';
import Card from '~/components/card/Card';
// Assets
// Custom components
import PropertiesTable from '~/views/admin/invoices/components/PropertiesTable';
import AuthFetch from '~/core/helpers/fetch';
import { API_BASE_URL } from '~/core/config';

import useTranslations from '~/core/hooks/useTranslations';

export default function UserReports() {

  const { t } = useTranslations();

  const [ invoices, setInvoices ] = React.useState( [] );

  const init = async () => {

    const invoicesJson = await AuthFetch.request( `${API_BASE_URL}/admin/invoices`, {
      method: 'GET',
      credentials: 'include',
    } );

    setInvoices( invoicesJson.data.map( ( invoiceData: any ) => ( {
      number: invoiceData.number,
      description: invoiceData.description,
      link: invoiceData.invoice_pdf,
      createdAt: invoiceData.created * 1000,
      price: `${( invoiceData.total / 100 ).toFixed( 2 )} €`,
      status: invoiceData.paid,
    } ) ) );

  };

  React.useEffect( () => {

    init();

  }, [] );

  const textColor = useColorModeValue( 'secondaryGray.900', 'white' );
  const textColorSecondary = 'gray.400';

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Card flexDirection="column" w="100%" px="0px" overflowX={{ sm: 'scroll', lg: 'hidden' }}>
        <Flex px="25px" justifyContent="space-between" align="center">
          <Box me="auto">
            <Heading color={textColor} fontSize="36px" mb="10px">
              { t( 'invoices.table.heading' ) }
            </Heading>
            <Text
              mb="36px"
              ms="4px"
              color={textColorSecondary}
              fontWeight="400"
              fontSize="md"
            >
              { t( 'invoices.table.description' ) }
            </Text>
          </Box>
        </Flex>
        <PropertiesTable tableData={invoices} />
      </Card>
    </Box>
  );

}
