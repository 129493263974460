import React from 'react';
import { Icon } from '@chakra-ui/react';
import {
  MdBarChart, MdPeople, MdHome, MdLock, MdFileOpen, MdDownload,
} from 'react-icons/md';

// Admin Imports
import MainDashboard from '~/views/admin/default';
import Property from '~/views/admin/property';
import Users from '~/views/admin/users';
import Company from '~/views/admin/company';
import CertifiedPerson from '~/views/admin/certifiedPerson';
import Invoices from '~/views/admin/invoices';
import Success from '~/views/admin/success';

// Auth Imports
import SignInCentered from '~/views/auth/signIn';
import SignUpCentered from '~/views/auth/signUp';

import useTranslations from '~/core/hooks/useTranslations';
import { useGlobalData } from '~/core/hooks/useGlobalData';

function routes() {

  const { t } = useTranslations();
  const { state } = useGlobalData();

  if ( state.user?.role === 'super_admin' ) {

    return [ {
      name: t( 'routes.properties' ),
      layout: '/admin',
      path: '/default',
      icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
      component: MainDashboard,
      notInMenu: true,
    }, {
      name: t( 'routes.users' ),
      layout: '/admin',
      path: '/users',
      icon: <Icon as={MdPeople} width="20px" height="20px" color="inherit" />,
      component: Users,
      secondary: true,
    } ];

  }

  return [
    {
      name: t( 'routes.properties' ),
      layout: '/admin',
      path: '/default',
      icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
      component: MainDashboard,
    },
    {
      name: '',
      layout: '/admin',
      path: '/property/:propertyId',
      icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
      component: Property,
      notInMenu: true,
    },
    {
      name: t( 'routes.payment' ),
      layout: '/admin',
      path: '/success/:propertyId?',
      icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
      component: Success,
      notInMenu: true,
    },
    {
      name: t( 'routes.users' ),
      layout: '/admin',
      path: '/users',
      icon: <Icon as={MdPeople} width="20px" height="20px" color="inherit" />,
      component: Users,
      secondary: true,
    },
    {
      name: t( 'routes.company' ),
      layout: '/admin',
      icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
      path: '/company',
      component: Company,
    },
    {
      name: t( 'routes.certifiedPerson' ),
      layout: '/admin',
      icon: <Icon as={MdFileOpen} width="20px" height="20px" color="inherit" />,
      path: '/certified-person',
      component: CertifiedPerson,
    },
    {
      name: t( 'routes.invoices' ),
      layout: '/admin',
      icon: <Icon as={MdDownload} width="20px" height="20px" color="inherit" />,
      path: '/invoices',
      component: Invoices,
    },
    {
      name: t( 'routes.signIn' ),
      layout: '/auth',
      path: '/sign-in',
      icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
      component: SignInCentered,
      notInMenu: true,
    },
    {
      name: t( 'routes.signUp' ),
      layout: '/auth',
      path: '/sign-up',
      icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
      component: SignUpCentered,
      notInMenu: true,
    },
    /* {
  name: 'Profile',
    layout: '/admin',
    path: '/profile',
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: Profile
  },
  {
    name: 'Sign In',
    layout: '/auth',
    path: '/sign-in',
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: SignInCentered
  },
  {
    name: 'RTL Admin',
    layout: '/rtl',
    path: '/rtl-default',
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: RTL
  } */
  ];

}

export default routes;
