/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|

 =========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included 
in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Box,
  useColorModeValue,
  Flex,
  Icon,
  Text,
  Heading,
  Spinner,
} from '@chakra-ui/react';
// Assets
import React from 'react';
import { useParams } from 'react-router-dom';
import { MdCheck } from 'react-icons/md';
// Custom components
import IconBox from '~/components/icons/IconBox';
import Card from '~/components/card/Card';
import AuthFetch from '~/core/helpers/fetch';
import { API_BASE_URL } from '~/core/config';

export default function Property() {

  const { propertyId } = useParams() as any;

  const [ success, setSuccess ] = React.useState( false );

  const getProperty = async () => {

    const property: any = await AuthFetch.request( `${API_BASE_URL}/property?propertyId=${propertyId}`, {
      method: 'GET',
      credentials: 'include',
    } );

    if ( property && property.paid ) {

      setSuccess( true );

      setTimeout( () => {

        window.location.href = `/#/admin/property/${propertyId}`;

      }, 2000 );

    } else {

      setTimeout( () => {

        getProperty();

      }, 2000 );

    }

  };

  const getUser = async () => {

    const user: any = await AuthFetch.request( `${API_BASE_URL}/property/user`, {
      method: 'GET',
      credentials: 'include',
    } );

    if ( user && user.validUntil && new Date( user.validUntil ) > new Date() ) {

      setSuccess( true );

      setTimeout( () => {

        window.location.href = '/';

      }, 2000 );

    } else {

      setTimeout( () => {

        getUser();

      }, 2000 );

    }

  };

  React.useEffect( () => {

    if ( propertyId ) {

      getProperty();

    } else {

      getUser();

    }

  }, [] );

  // Chakra Color Mode
  const brandColor = useColorModeValue( 'brand.500', 'white' );
  const textColor = useColorModeValue( 'secondaryGray.900', 'white' );
  const textColorSecondary = 'gray.400';
  const brandStars = useColorModeValue( 'brand.500', 'brand.400' );

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Card mb="20px" mx="auto">
        <Box textAlign="center">
          <Heading color={textColor} fontSize="36px" mb="20px">
            { success ? 'Payment Successful' : 'Processing Payment' }
          </Heading>
          <Flex justifyContent="center" alignItems="center" gap={5}>
            { success ? (
              <IconBox
                w="56px"
                h="56px"
                bg="brand.100"
                icon={<Icon w="32px" h="32px" as={MdCheck} color={brandStars} />}
              />
            )
              : ( <Spinner color={brandColor} size="lg" /> )}
            <Text color={textColorSecondary} fontWeight="400" fontSize="md">
              { success ? 'You will be redirected now' : 'Please wait while we receive the payment' }
            </Text>
          </Flex>
        </Box>
      </Card>
    </Box>
  );

}
