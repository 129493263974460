export default {
  lang: {
    heading: 'Language',
    langs: {
      en: 'English',
      sk: 'Slovak',
    },
  },
  routes: {
    properties: 'Properties',
    payment: 'Payment',
    users: 'Users',
    company: 'Company',
    certifiedPerson: 'Certified Person',
    invoices: 'Invoices',
    signIn: 'Sign In',
    signUp: 'Sign Up',
    pages: 'Pages',
  },
  auth: {
    email: {
      label: 'Email',
      placeholder: 'your@email.com',
    },
    password: {
      label: 'Password',
      placeholder: 'Min. 8 characters',
    },
    checkbox: {
      label: 'Keep me logged in',
    },
    submit: 'Sign In',
    signIn: {
      heading: 'Sign In',
      description: 'Enter your email and password to sign in!',
      notRegistered: 'Not registered yet?',
      createAccount: 'Create an Account',
      back: 'Back to Main Web Page',
    },
    signUp: {
      heading: 'Register your new account',
      description: 'Enter your email and password to sign up!',
      back: 'Back to Login',
    },
  },
  properties: {
    new: {
      heading: 'Create a new property',
      description: 'Enter name and street to create a new one!',
      name: {
        label: 'Name',
        placeholder: 'Your new property',
      },
      street: {
        label: 'Street',
        placeholder: 'Street 123, 00000 United States',
      },
      checkbox: {
        label: 'I need sign every flat instead of one property signature',
      },
      new: 'New property',
      close: 'Close',
      submit: 'Create property',
    },
    table: {
      heading: 'List of your properties',
      description: 'Click to one for more details!',
      headers: {
        name: 'Name',
        street: 'Street',
        date: 'Date',
      },
      empty: {
        heading: 'Nothing here to see',
        description: 'Start adding properties and delegate access to users',
        button: 'Let\'s create a property!',
      },
    },
  },
  property: {
    totalPrice: 'Total price',
    pricePerDevice: 'Price per device',
    installedDevices: 'Installed devices',
    flats: 'Flats',
    export: {
      heading: 'Export documents',
      action: 'Export',
      pending: 'Please wait until we process all documents',
      download: {
        excel: 'Download Excel of all devices',
        zip: 'Download .zip of all flats',
      },
    },
    usersTable: {
      fullname: 'Fullname',
      email: 'Email',
      assign: 'Assign',
      remove: 'Remove',
      available: {
        heading: 'Available users',
        description: 'Assign users to this property',
      },
      assigned: {
        heading: 'Assigned users',
        description: 'This users can add flats and devices to this property',
      },
    },
    new: {
      heading: 'Create a new flat in %{name}',
      description: 'Enter name to create a new one!',
      name: {
        label: 'Name',
        placeholder: 'Your new flat',
      },
      OM: {
        label: 'OM',
        placeholder: 'OM',
      },
      MP: {
        label: 'MP',
        placeholder: 'MP',
      },
      new: 'New flat',
      close: 'Close',
      submit: 'Create flat',
    },
    table: {
      heading: 'Flats in this property',
      description: 'You can export when all flats will be closed',
      headers: {
        name: 'Name',
        closed: 'Closed',
        created: 'Created',
      },
      empty: {
        heading: 'Nothing here to see',
        description: 'Start adding flats in this property',
        button: 'Let\'s create a flat!',
      },
    },
    license: {
      payForExport: 'Pay for property export',
      payForLicence: 'You need to pay license first',
      pricePerDevice: 'One changed device in your flat cost you %{pricePerDevice} €.',
      devicesCount: 'You have %{devicesCount} devices.',
      buyLicence: 'You can buy license for 1 year.',
      cancel: 'Cancel',
      pay: 'Pay',
    },
    noCertifiedPerson: {
      title: 'You don\'t have certified person set',
      description: 'Please set your certified person to be able to export documents',
    },
  },
  users: {
    new: {
      heading: 'Create a new user',
      description: 'Enter email and fullname to create a new one!',
      email: {
        label: 'Email',
        placeholder: 'your@email.com',
      },
      fullname: {
        label: 'Fullname',
        placeholder: 'John Doe',
      },
      password: {
        label: 'Password',
        placeholder: 'Password',
      },
      price: {
        label: 'Price for 1 montague protocol',
      },
      new: 'New user',
      close: 'Close',
      submit: 'Create user',
    },
    table: {
      heading: 'List of your users',
      description: 'Every user needs to be assign to property individually!',
      headers: {
        fullname: 'Fullname',
        email: 'Email',
        date: 'Date',
      },
      empty: {
        heading: 'Nothing here to see',
        description: 'Start adding users and delegate their access to the property',
        button: 'Let\'s create a user!',
      },
    },
  },
  company: {
    heading: 'Company information',
    description: 'Set the correct informations it will appear in the invoice',
    submit: 'Update company',
  },
  certifiedPerson: {
    info: {
      heading: 'Address',
      description: 'Set the correct informations it will appear in the export',
      submit: 'Update address',
    },
    upload: {
      heading: 'Upload your stamp',
      description: 'Your stamp will be uploaded automatically',
      upload: 'Upload File',
      onlyPng: 'only PNG file is allowed',
    },
  },
  invoices: {
    table: {
      heading: 'List of invoices',
      description: 'You can download them in PDF format',
      headers: {
        download: 'Download',
        invoiceNo: 'Invoice No',
        description: 'Description',
        created: 'Created',
        price: 'Price',
        status: 'Status',
      },
      paid: 'Paid',
      unpaid: 'Unpaid',
      empty: {
        heading: 'Nothing here to see',
        description: 'Your invoices will be listed here',
      },
    },
  },
  address: {
    name: {
      label: 'Company name',
      placeholder: 'DS Corporation, s.r.o.',
    },
    city: {
      label: 'City',
      placeholder: 'Barcelona',
    },
    street: {
      label: 'Street',
      placeholder: 'Carrer de la Marina, 16',
    },
    postalCode: {
      label: 'Postal code',
      placeholder: '08005',
    },
    country: {
      label: 'Country',
      placeholder: 'Select country',
    },
    vatNo: {
      label: 'Vat No.',
      placeholder: 'SK1234567890',
    },
    description: {
      label: 'Description',
      placeholder: 'Authorization no: R/I - 066/2022',
    },
  },
  footer: {
    admin: {
      copyright: 'DS Corporation, s.r.o. MONTYO dashboard. All Rights Reserved. Made with love.',
    },
    auth: {
      copyright: 'DS Corporation, s.r.o. All Rights Reserved. Made with love.',
    },
    links: {
      webpage: 'Web',
      support: 'Support',
      terms: 'Terms of Use',
      privacy: 'Privacy Policy',
    },
  },
  error: '%{label} field is required',
  invalidError: '%{label} is invalid',
};
