import {
  Box, Flex, Button, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, Heading,
} from '@chakra-ui/react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
// Custom components
import * as React from 'react';
import Card from '~/components/card/Card';
import useTranslations from '~/core/hooks/useTranslations';
import EmptyTable, { EmptyTableMethods } from '~/components/emptyTable';

type RowObj = {
  id: number;
  email: string;
  fullname: string;
  createdAt: string;
};

const columnHelper = createColumnHelper<RowObj>();

// const columns = columnsDataCheck;
export default function PropertiesTable( props: { type: 'DELETE' | 'ADD', tableData: any; title: string; description: string; assign: ( ( userId: number ) => void ) | undefined } ) {

  const { t } = useTranslations();

  const {
    tableData, title, description, assign, type,
  } = props;
  const [ sorting, setSorting ] = React.useState<SortingState>( [] );
  const textColor = useColorModeValue( 'secondaryGray.900', 'white' );
  const textColorSecondary = 'gray.400';
  const borderColor = useColorModeValue( 'gray.200', 'whiteAlpha.100' );

  const nameHeader = () => (
    <Text
      justifyContent="space-between"
      align="center"
      fontSize={{ sm: '10px', lg: '12px' }}
      color="gray.400"
      textTransform="uppercase"
    >
      { t( 'property.usersTable.fullname' ) }
    </Text>
  );

  const nameCell = ( info: any ) => (
    <Flex align="center">
      <Text color={textColor} fontSize="sm" fontWeight="700">
        {info.getValue() || 'Admin (me)'}
      </Text>
    </Flex>
  );

  const emailHeader = () => (
    <Text
      justifyContent="space-between"
      align="center"
      fontSize={{ sm: '10px', lg: '12px' }}
      color="gray.400"
      textTransform="uppercase"
    >
      { t( 'property.usersTable.email' ) }
    </Text>
  );

  const emailCell = ( info: any ) => (
    <Flex align="center">
      <Text color={textColor} fontSize="sm">
        {info.getValue()}
      </Text>
    </Flex>
  );

  const dateHeader = () => (
    <Text
      justifyContent="space-between"
      align="center"
      fontSize={{ sm: '10px', lg: '12px' }}
      color="gray.400"
      textTransform="uppercase"
    >
      { type === 'DELETE' ? t( 'property.usersTable.remove' ) : t( 'property.usersTable.assign' ) }
    </Text>
  );

  const dateCell = ( info: any ) => {

    if ( assign ) {

      if ( !info.row.original.fullname ) {

        return null;

      }

      return (
        <Button
          backgroundColor={type === 'DELETE' ? 'deeppink' : undefined}
          onClick={() => {

            assign( info.row.original.id );

          }}
          size="xs"
          variant="brand"
        >
          {type === 'DELETE' ? t( 'property.usersTable.remove' ) : t( 'property.usersTable.assign' )}
        </Button>
      );

    }
    const date = new Date( info.getValue() );
    const formattedDate = date.toLocaleDateString( 'sk-SK', {
      day: 'numeric',
      month: 'long',
    } );
    const formattedTime = date.toLocaleTimeString( 'sk-SK', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    } );

    return (
      <Text color={textColor} fontSize="sm" fontWeight="700">
        {`${formattedDate}, ${formattedTime}`}
      </Text>
    );

  };

  const columns = [
    columnHelper.accessor( 'fullname', {
      id: 'name',
      header: () => (
        nameHeader()
      ),
      cell: ( info ) => (
        nameCell( info )
      ),
    } ),
    columnHelper.accessor( 'email', {
      id: 'email',
      header: () => (
        emailHeader()
      ),
      cell: ( info ) => (
        emailCell( info )
      ),
    } ),
    columnHelper.accessor( 'createdAt', {
      id: 'date',
      header: () => (
        dateHeader()
      ),
      cell: ( info ) => (
        dateCell( info )
      ),
    } ),
  ];
  const [ data, setData ] = React.useState( () => tableData );

  const emptyTableRef = React.useRef< EmptyTableMethods >( null );

  React.useEffect( () => {

    setData( tableData );

  }, [ tableData ] );
  const table = useReactTable( {
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
    onStateChange: () => emptyTableRef.current?.setLoading( false ),
  } );

  return (
    <Card flexDirection="column" w="100%" px="0px" overflowX={{ sm: 'scroll', lg: 'hidden' }}>
      <Flex px="25px" justifyContent="space-between" align="center">
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            {title}
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            {description}
          </Text>
        </Box>
      </Flex>
      <Box>
        {!table.getRowCount()
          ? (
            <EmptyTable table="users" ref={emptyTableRef} />
          )
          : (
            <Table variant="simple" color="gray.500" mb="24px">
              <Thead>
                {table.getHeaderGroups().map( ( headerGroup ) => (
                  <Tr key={headerGroup.id}>
                    {headerGroup.headers.map( ( header ) => (
                      <Th
                        key={header.id}
                        colSpan={header.colSpan}
                        pe="10px"
                        borderColor={borderColor}
                        cursor="pointer"
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        <Flex
                          justifyContent="space-between"
                          align="center"
                          fontSize={{ sm: '10px', lg: '12px' }}
                          color="gray.400"
                        >
                          {flexRender( header.column.columnDef.header, header.getContext() )}
                          {{
                            asc: '',
                            desc: '',
                          }[header.column.getIsSorted() as string] ?? null}
                        </Flex>
                      </Th>
                    ) )}
                  </Tr>
                ) )}
              </Thead>
              <Tbody>
                {table.getRowModel().rows.map( ( row ) => (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map( ( cell ) => (
                      <Td
                        key={cell.id}
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        borderColor="transparent"
                      >
                        {flexRender( cell.column.columnDef.cell, cell.getContext() )}
                      </Td>
                    ) )}
                  </Tr>
                ) )}
              </Tbody>
            </Table>
          )}
      </Box>
    </Card>
  );

}
