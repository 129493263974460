/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|

 =========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included 
in all copies or substantial portions of the Software.

*/

import React from 'react';
import { NavLink } from 'react-router-dom';
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  FormHelperText,
} from '@chakra-ui/react';
// Custom components
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import Cookies from 'js-cookie';
import DefaultAuth from '~/layouts/auth/Default';
// Assets
import { API_BASE_URL } from '~/core/config';
import { validateEmail } from '~/core/helpers/string';
import useTranslations from '~/core/hooks/useTranslations';

function SignIn() {

  const { t } = useTranslations();

  // Chakra color mode
  const textColor = useColorModeValue( 'navy.700', 'white' );
  const textColorSecondary = 'gray.400';
  const textColorDetails = useColorModeValue( 'navy.700', 'secondaryGray.600' );
  const textColorBrand = useColorModeValue( 'brand.500', 'white' );
  const brandStars = useColorModeValue( 'brand.500', 'brand.400' );
  const errorColor = 'red.500';

  const [ show, setShow ] = React.useState( false );
  const handleClick = () => setShow( !show );

  const [ email, setEmail ] = React.useState( '' );
  const [ password, setPassword ] = React.useState( '' );

  const [ emailError, setEmailError ] = React.useState( false );
  const [ emailInvalidError, setEmailInvalidError ] = React.useState( false );
  const [ passwordError, setPasswordError ] = React.useState( false );

  const handleLogin = async ( event: any ) => {

    event.preventDefault();

    if ( !email ) {

      setEmailError( true );

    } else if ( !validateEmail( email ) ) {

      setEmailInvalidError( true );

    }

    if ( !password ) {

      setPasswordError( true );

    }

    if ( !email || !password || !validateEmail( email ) ) {

      return;

    }

    try {

      const response = await fetch( `${API_BASE_URL}/user/login`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify( { email, password } ),
      } );

      const responseJson: any = await response.json();

      if ( responseJson?.accessToken ) {

        const CookieDate = new Date(); CookieDate.setFullYear( CookieDate.getFullYear() + 1 );
        Cookies.set( 'accessToken', responseJson?.accessToken, { expires: CookieDate } );
        window.location.href = '/';

      }

    } catch ( error ) {

      // eslint-disable-next-line no-console
      console.error( 'Network error:', error );

    }

  };

  return (
    <DefaultAuth backHref="https://montyo.eu" backText={t( 'auth.signIn.back' )}>
      <Flex
        maxW={{ base: '100%', md: 'max-content' }}
        w="100%"
        mx={{ base: 'auto', lg: '0px' }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: '30px', md: '60px' }}
        px={{ base: '25px', md: '0px' }}
        mt={{ base: '40px', md: '14vh' }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            { t( 'auth.signIn.heading' ) }
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            { t( 'auth.signIn.description' ) }
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: '100%', md: '420px' }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: 'auto', lg: 'unset' }}
          me="auto"
          mb={{ base: '20px', md: 'auto' }}
        >
          {/* <Button
            fontSize='sm'
            me='0px'
            mb='26px'
            py='15px'
            h='50px'
            borderRadius='16px'
            bg={googleBg}
            color={googleText}
            fontWeight='500'
            _hover={googleHover}
            _active={googleActive}
            _focus={googleActive}>
            <Icon as={FcGoogle} w='20px' h='20px' me='10px' />
            Sign in with Google
          </Button>
          <Flex align='center' mb='25px'>
            <HSeparator />
            <Text color='gray.400' mx='14px'>
              or
            </Text>
            <HSeparator />
          </Flex>
          */}
          <FormControl>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              { t( 'auth.email.label' ) }
              <Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired
              variant="auth"
              fontSize="sm"
              ms={{ base: '0px', md: '0px' }}
              type="email"
              placeholder={t( 'auth.email.placeholder' )}
              fontWeight="500"
              size="lg"
              borderColor={emailError || emailInvalidError ? errorColor : undefined}
              onChange={( e ) => {

                setEmail( e.target.value );
                setEmailError( false );
                setEmailInvalidError( false );

              }}
            />
            {emailError && (
              <FormHelperText ms="4px" color={errorColor}>
                { t( 'error', { label: t( 'auth.email.label' ) } ) }
              </FormHelperText>
            )}
            {emailInvalidError && (
              <FormHelperText ms="4px" color={errorColor}>
                { t( 'invalidError', { label: t( 'auth.email.label' ) } ) }
              </FormHelperText>
            )}
            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              display="flex"
              mt="24px"
            >
              { t( 'auth.password.label' ) }
              <Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size="md">
              <Input
                isRequired
                fontSize="sm"
                placeholder={t( 'auth.password.placeholder' )}
                size="lg"
                type={show ? 'text' : 'password'}
                variant="auth"
                borderColor={passwordError ? errorColor : undefined}
                onChange={( e ) => {

                  setPassword( e.target.value );
                  setPasswordError( false );

                }}
              />
              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: 'pointer' }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
            {passwordError && (
              <FormHelperText ms="4px" color={errorColor}>
                { t( 'error', { label: t( 'auth.password.label' ) } ) }
              </FormHelperText>
            )}
            <Flex justifyContent="space-between" align="center" my="24px">
              <FormControl display="flex" alignItems="center">
                <Checkbox
                  id="remember-login"
                  colorScheme="brandScheme"
                  me="10px"
                />
                <FormLabel
                  htmlFor="remember-login"
                  mb="0"
                  fontWeight="normal"
                  color={textColor}
                  fontSize="sm"
                >
                  { t( 'auth.checkbox.label' ) }
                </FormLabel>
              </FormControl>
              {/* <NavLink to='/auth/forgot-password'>
                <Text
                  color={textColorBrand}
                  fontSize='sm'
                  w='124px'
                  fontWeight='500'>
                  Forgot password?
                </Text>
              </NavLink> */}
            </Flex>
            <Button
              onClick={handleLogin}
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
            >
              { t( 'auth.submit' ) }
            </Button>
          </FormControl>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          >
            <Text color={textColorDetails} fontWeight="400" fontSize="14px">
              { t( 'auth.signIn.notRegistered' ) }
              <NavLink to="/auth/sign-up">
                <Text
                  color={textColorBrand}
                  as="span"
                  ms="5px"
                  fontWeight="500"
                >
                  { t( 'auth.signIn.createAccount' ) }
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );

}

export default SignIn;
