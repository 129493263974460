// Chakra imports
import {
  Box, Flex, Icon, Text,
} from '@chakra-ui/react';
import React from 'react';
import { FaChevronLeft } from 'react-icons/fa';
import Footer from '~/components/footer/FooterAuth';
import FixedPlugin from '~/components/fixedPlugin/FixedPlugin';
import { AquaLogo } from '~/components/icons/Icons';
import useTranslations from '~/core/hooks/useTranslations';

function AuthIllustration(
  props: { children: JSX.Element | string; backHref: string; backText: string },
) {

  const { children, backHref, backText } = props;

  const { setLocale } = useTranslations();
  setLocale();

  return (
    <Flex position="relative" h="max-content">
      <Flex
        h={{
          sm: 'initial',
          md: 'unset',
          lg: '100vh',
          xl: '97vh',
        }}
        w="100%"
        maxW={{ md: '66%', lg: '1313px' }}
        mx="auto"
        pt={{ sm: '50px', md: '0px' }}
        px={{ lg: '30px', xl: '0px' }}
        ps={{ xl: '70px' }}
        justifyContent="start"
        direction="column"
      >
        <a
          href={backHref}
          style={{
            width: 'fit-content',
            marginTop: '40px',
          }}
        >
          <Flex align="center" ps={{ base: '25px', lg: '0px' }} pt={{ lg: '0px', xl: '0px' }} w="fit-content">
            <Icon as={FaChevronLeft} me="12px" h="13px" w="8px" color="secondaryGray.600" />
            <Text ms="0px" fontSize="sm" color="secondaryGray.600">
              {backText}
            </Text>
          </Flex>
        </a>
        {children}
        <Box
          display={{ base: 'none', md: 'block' }}
          h="100%"
          minH="100vh"
          w={{ lg: '50vw', '2xl': '44vw' }}
          position="absolute"
          right="0px"
        >
          <Flex
            bg="#0038FF"
            justify="center"
            align="center"
            w="100%"
            h="100%"
            bgSize="cover"
            bgPosition="50%"
            position="absolute"
            borderBottomLeftRadius={{ lg: '120px', xl: '200px' }}
          >
            <AquaLogo h="100px" w="400px" color="white" />
          </Flex>
        </Box>
        <Footer />
      </Flex>
      <FixedPlugin />
    </Flex>
  );

}

export default AuthIllustration;
