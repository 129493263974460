/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|

=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included 
in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Box,
  useColorModeValue,
  Flex,
  SimpleGrid,
  FormControl,
  FormLabel,
  Input,
  Button,
  Text,
  Heading,
  InputGroup,
  useToast,
  FormHelperText,
} from '@chakra-ui/react';
// Assets
import React from 'react';
// Custom components
import Upload from '~/views/admin/certifiedPerson/components/Upload';
import Card from '~/components/card/Card';
import AuthFetch from '~/core/helpers/fetch';
import { API_BASE_URL } from '~/core/config';
import useTranslations from '~/core/hooks/useTranslations';
import CountrySelector from '~/components/countrySelector';

export default function UserReports() {

  const { t } = useTranslations();

  const toast = useToast();

  const [ formData, setFormData ] = React.useState( {
    name: '',
    city: '',
    street: '',
    postalCode: '',
    country: '',
    vatNo: '',
    description: '',
  } );

  const [ formErrors, setFormErrors ] = React.useState( [] );

  const init = async () => {

    const addresses: any = await AuthFetch.request( `${API_BASE_URL}/admin/addresses`, {
      method: 'GET',
      credentials: 'include',
    } );

    if ( addresses.certified_person ) {

      setFormData( {
        name: addresses.certified_person.name,
        city: addresses.certified_person.city,
        street: addresses.certified_person.street,
        postalCode: addresses.certified_person.postalCode,
        country: addresses.certified_person.country,
        vatNo: addresses.certified_person.vatNo,
        description: addresses.certified_person.description ?? '',
      } );

    }

  };

  const handleFormDataChange = ( field: string, value: string ) => {

    if ( field !== 'description' ) {

      if ( value.trim() === '' && !formErrors.includes( field ) ) {

        setFormErrors( [ ...formErrors, field ] );

      } else {

        setFormErrors( formErrors.filter( ( error ) => error !== field ) );

      }

    }

    setFormData( ( prevState ) => ( {
      ...prevState,
      [field]: value,
    } ) );

  };

  const setCompany = async ( event: any ) => {

    event.preventDefault();

    const errorFields: string[] = [];

    Object.entries( formData ).forEach( ( [ field, value ] ) => {

      if ( field !== 'description' && value.trim() === '' ) {

        errorFields.push( field );

      }

    } );

    setFormErrors( errorFields );

    if ( errorFields.length ) {

      return;

    }

    await AuthFetch.request( `${API_BASE_URL}/admin/set-address`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },

      body: JSON.stringify( {
        ...formData, description: formData.description ?? null, type: 'certified_person',
      } ),
    } );

    toast( {
      title: 'Certified person updated.',
      description: "We've update your person for you.",
      status: 'success',
      duration: 3000,
      isClosable: true,
    } );

  };

  React.useEffect( () => {

    init();

  }, [] );
  // Chakra Color Mode
  const textColor = useColorModeValue( 'secondaryGray.900', 'white' );
  const textColorSecondary = 'gray.400';
  const brandStars = useColorModeValue( 'brand.500', 'brand.400' );
  const errorColor = 'red.500';

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      {/* Content Area */}
      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
        <Card w="100%">
          <Box me="auto">
            <Heading color={textColor} fontSize="36px" mb="10px">
              { t( 'certifiedPerson.info.heading' ) }
            </Heading>
            <Text
              mb="36px"
              ms="4px"
              color={textColorSecondary}
              fontWeight="400"
              fontSize="md"
            >
              { t( 'certifiedPerson.info.description' ) }
            </Text>
          </Box>
          <Flex
            direction="column"
            w={{ base: '100%' }}
            maxW="100%"
            background="transparent"
            borderRadius="15px"
            mx={{ base: 'auto', lg: 'unset' }}
            me="auto"
            mb={{ base: '20px', md: 'auto' }}
          >
            <FormControl>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                { t( 'address.name.label' ) }
                <Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                value={formData.name}
                isRequired
                variant="auth"
                fontSize="sm"
                ms={{ base: '0px', md: '0px' }}
                type="text"
                placeholder={t( 'address.name.placeholder' )}
                fontWeight="500"
                size="lg"
                borderColor={formErrors.includes( 'name' ) ? errorColor : undefined}
                onChange={( e ) => handleFormDataChange( 'name', e.target.value )}
              />
              {formErrors.includes( 'name' ) && (
                <FormHelperText ms="4px" color={errorColor}>
                  { t( 'error', { label: t( 'address.name.label' ) } ) }
                </FormHelperText>
              )}
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
                mt="24px"
              >
                { t( 'address.city.label' ) }
                <Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size="md">
                <Input
                  value={formData.city}
                  isRequired
                  fontSize="sm"
                  placeholder={t( 'address.city.placeholder' )}
                  size="lg"
                  type="text"
                  variant="auth"
                  borderColor={formErrors.includes( 'city' ) ? errorColor : undefined}
                  onChange={( e ) => handleFormDataChange( 'city', e.target.value )}
                />
              </InputGroup>
              {formErrors.includes( 'city' ) && (
                <FormHelperText ms="4px" color={errorColor}>
                  { t( 'error', { label: t( 'address.city.label' ) } ) }
                </FormHelperText>
              )}
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
                mt="24px"
              >
                { t( 'address.street.label' ) }
                <Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size="md">
                <Input
                  value={formData.street}
                  isRequired
                  fontSize="sm"
                  placeholder={t( 'address.street.placeholder' )}
                  size="lg"
                  type="text"
                  variant="auth"
                  borderColor={formErrors.includes( 'street' ) ? errorColor : undefined}
                  onChange={( e ) => handleFormDataChange( 'street', e.target.value )}
                />
              </InputGroup>
              {formErrors.includes( 'street' ) && (
                <FormHelperText ms="4px" color={errorColor}>
                  { t( 'error', { label: t( 'address.street.label' ) } ) }
                </FormHelperText>
              )}
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
                mt="24px"
              >
                { t( 'address.postalCode.label' ) }
                <Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size="md">
                <Input
                  value={formData.postalCode}
                  isRequired
                  fontSize="sm"
                  placeholder={t( 'address.postalCode.placeholder' )}
                  size="lg"
                  type="text"
                  variant="auth"
                  borderColor={formErrors.includes( 'postalCode' ) ? errorColor : undefined}
                  onChange={( e ) => handleFormDataChange( 'postalCode', e.target.value )}
                />
              </InputGroup>
              {formErrors.includes( 'postalCode' ) && (
                <FormHelperText ms="4px" color={errorColor}>
                  { t( 'error', { label: t( 'address.postalCode.label' ) } ) }
                </FormHelperText>
              )}
              <CountrySelector
                formData={formData}
                formErrors={formErrors}
                errorColor={errorColor}
                textColor={textColor}
                brandStars={brandStars}
                handleFormDataChange={handleFormDataChange}
              />
              {formErrors.includes( 'country' ) && (
                <FormHelperText ms="4px" color={errorColor}>
                  { t( 'error', { label: t( 'address.country.label' ) } ) }
                </FormHelperText>
              )}
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
                mt="24px"
              >
                { t( 'address.vatNo.label' ) }
                <Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size="md">
                <Input
                  value={formData.vatNo}
                  isRequired
                  fontSize="sm"
                  placeholder={t( 'address.vatNo.placeholder' )}
                  size="lg"
                  type="text"
                  variant="auth"
                  borderColor={formErrors.includes( 'vatNo' ) ? errorColor : undefined}
                  onChange={( e ) => handleFormDataChange( 'vatNo', e.target.value )}
                />
              </InputGroup>
              {formErrors.includes( 'vatNo' ) && (
                <FormHelperText ms="4px" color={errorColor}>
                  { t( 'error', { label: t( 'address.vatNo.label' ) } ) }
                </FormHelperText>
              )}
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
                mt="24px"
              >
                { t( 'address.description.label' ) }
              </FormLabel>
              <InputGroup size="md">
                <Input
                  value={formData.description}
                  isRequired={false}
                  fontSize="sm"
                  placeholder={t( 'address.description.placeholder' )}
                  mb="24px"
                  size="lg"
                  type="text"
                  variant="auth"
                  onChange={( e ) => handleFormDataChange( 'description', e.target.value )}
                />
              </InputGroup>
              <Button
                onClick={setCompany}
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
              >
                { t( 'certifiedPerson.info.submit' ) }
              </Button>
            </FormControl>
          </Flex>
        </Card>
        <Upload />
      </SimpleGrid>
    </Box>
  );

}
