// Chakra Imports
import {
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  useColorModeValue,
  Text,
  Flex,
  Icon,
} from '@chakra-ui/react';
import React from 'react';
import { IoIosArrowUp } from 'react-icons/io';

import useTranslations from '~/core/hooks/useTranslations';

export default function LanguageSelector() {

  const {
    t, setLocale, currentLanguage, langs,
  } = useTranslations();
  setLocale();

  const ethBg = useColorModeValue( 'secondaryGray.300', 'navy.900' );
  const ethColor = useColorModeValue( 'gray.700', 'white' );
  const ethBox = useColorModeValue( 'white', 'navy.800' );

  return (
    <Menu>
      <MenuButton as={Button} display="flex" p="6px" ms="10px" me="30px" mt="auto" mb="52px" w="fit-content" h="auto" fontSize="sm" bg={ethBg} borderRadius="30px">
        <Flex align="center">
          <Flex align="center" justify="center" bg={ethBox} h="29px" w="29px" borderRadius="30px" me="7px">
            <Icon color={ethColor} w="15px" h="20px" as={IoIosArrowUp} />
          </Flex>
          <Text w="max-content" color={ethColor} fontSize="sm" fontWeight="700" me="6px">
            { t( 'lang.heading' ) }
          </Text>
        </Flex>
      </MenuButton>
      <MenuList fontSize="sm" bg={ethBg}>
        <MenuOptionGroup value={currentLanguage} type="radio">
          {langs.map( ( lang ) => (
            <MenuItemOption value={lang} key={lang} onClick={() => setLocale( lang )}>
              { t( `lang.langs.${lang}` ) }
            </MenuItemOption>
          ) )}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );

}
