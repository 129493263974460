import React, { forwardRef, useImperativeHandle } from 'react';
import {
  Flex, Heading, Text, Button, Icon,
} from '@chakra-ui/react';
import { HiEmojiSad } from 'react-icons/hi';
import { HiMiniArrowLongRight } from 'react-icons/hi2';

import useTranslations from '~/core/hooks/useTranslations';

export type EmptyTableMethods = {
  setLoading: ( boolean: boolean ) => void
};

export type EmptyTableProps = {
  table: string, onOpen?: any
};

const EmptyTable = forwardRef<EmptyTableMethods, EmptyTableProps>(
  ( { table, onOpen }, ref ) => {

    const { t } = useTranslations();

    const [ loading, setLoading ] = React.useState( true );

    useImperativeHandle( ref, () => ( {
      setLoading: ( boolean ) => setLoading( boolean ),
    } ) );

    if ( loading ) {

      return ( null );

    }

    return (
      <Flex p="24px" pb="40px" flexDirection="column" alignItems="center" textAlign="center">
        <Icon as={HiEmojiSad} width="40px" height="40px" color="inherit" />

        <Heading as="h3" fontSize="24px" pt="8px">
          { t( `${table}.table.empty.heading` ) }
        </Heading>

        <Text pt="12px">
          { t( `${table}.table.empty.description` ) }
        </Text>

        {onOpen && (
        <Button variant="link" colorScheme="brandScheme" mt="12px" onClick={onOpen}>
          <Text pe="6px">
            { t( `${table}.table.empty.button` ) }
          </Text>

          <Icon as={HiMiniArrowLongRight} width="22px" height="22px" color="inherit" />
        </Button>
        )}
      </Flex>
    );

  },
);

EmptyTable.defaultProps = {
  onOpen: false,
};

export default EmptyTable;
