// Chakra imports
import { Button, Flex, useColorModeValue } from '@chakra-ui/react';
// Assets
import React from 'react';
import { useDropzone } from 'react-dropzone';

function Dropzone( props: { content: JSX.Element | string; [x: string]: any } ) {

  const { content, onFile, ...rest } = props;
  const [ files, setFiles ] = React.useState<File[]>( [] );
  const { getRootProps, getInputProps } = useDropzone( {
    maxFiles: 1,
    accept: [ 'image/png' ],
    onDrop: setFiles,
  } );
  const bg = useColorModeValue( 'gray.100', 'navy.700' );
  const borderColor = useColorModeValue( 'secondaryGray.100', 'whiteAlpha.100' );

  React.useEffect( () => {

    if ( files.length ) {

      onFile?.( files[0] );

    }

  }, [ files, onFile ] );

  return (
    <Flex
      align="center"
      justify="center"
      bg={bg}
      border="1px dashed"
      borderColor={borderColor}
      borderRadius="16px"
      w="100%"
      h="max-content"
      minH="100%"
      cursor="pointer"
      {...getRootProps( { className: 'dropzone' } )}
      {...rest}
    >
      <input {...getInputProps()} />
      <Button height="100%" variant="no-effects">{content}</Button>
    </Flex>
  );

}

export default Dropzone;
