import Cookies from 'js-cookie';
import { API_BASE_URL } from '~/core/config';

class AuthFetch {

  private static async refreshSession(): Promise<void> {

    const response = await fetch( `${API_BASE_URL}/user/refresh-token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Cookies.get( 'accessToken' )}`,
      },
      credentials: 'include',
    } );

    if ( !response.ok ) {

      throw new Error( 'Session refresh failed' );

    }

    return response.json();

  }

  public static async request( input: RequestInfo, init?: RequestInit ): Promise<any> {

    init = init || {};

    if ( !init.headers ) {

      init.headers = new Headers();

    } else if ( !( init.headers instanceof Headers ) ) {

      init.headers = new Headers( init.headers );

    }

    init.headers.append( 'Authorization', `Bearer ${Cookies.get( 'accessToken' )}` );

    let response = await fetch( input, init );

    if ( response.status === 401 ) {

      // If unauthorized, try to refresh the session
      const { accessToken } = await AuthFetch.refreshSession() as any;
      const CookieDate = new Date(); CookieDate.setFullYear( CookieDate.getFullYear() + 1 );
      Cookies.set( 'accessToken', accessToken, { expires: CookieDate } );

      // Retry the original request with the same input and init
      init.headers.set( 'Authorization', `Bearer ${accessToken}` );
      response = await fetch( input, init );

    }

    if ( !response.ok ) {

      throw new Error( `Request failed with status ${response.status}` );

    }

    try {

      const data = await response.json();

      return data;

    } catch ( error: any ) {

      return response.text();

    }

  }

}

export default AuthFetch;
