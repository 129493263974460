import { mode } from '@chakra-ui/theme-tools';

export const modalStyles = {
  components: {
    Modal: {
      baseStyle: ( props: any ) => ( {
        dialog: {
          borderRadius: '20px',
          wordWrap: 'break-word',
          bg: mode( '#ffffff', 'navy.800' )( props ),
        },
      } ),
    },
  },
};
