// Chakra imports
import {
  Box, Flex, Icon, Text, Image, useColorModeValue,
} from '@chakra-ui/react';
// Assets
import React from 'react';
import { MdUpload } from 'react-icons/md';
// Custom components
import Card from '~/components/card/Card';
import Dropzone from '~/views/admin/certifiedPerson/components/Dropzone';
import { useGlobalData } from '~/core/hooks/useGlobalData';
import { API_CDN_URL } from '~/core/config';
import useTranslations from '~/core/hooks/useTranslations';

export default function Upload( props: { used?: number; total?: number; [x: string]: any } ) {

  const { t } = useTranslations();

  const { state } = useGlobalData();
  const { used, total, ...rest } = props;
  const [ stampUrl, setStampUrl ] = React.useState( '' );

  const onFile = async ( file: File ) => {

    const formData = new FormData();
    formData.append( 'file', file );
    const upload: any = await fetch( `${API_CDN_URL}/upload/stamp/${state?.user?.id}`, {
      method: 'POST',
      body: formData,
    } );

    if ( upload.ok ) {

      setStampUrl( URL.createObjectURL( file ) );

    }

  };

  React.useEffect( () => {

    if ( state?.user ) {

      setStampUrl( `${API_CDN_URL}/uploads/stamps/${state?.user?.id}.png` );

    }

  }, [ state.user ] );

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue( 'secondaryGray.900', 'white' );
  const brandColor = useColorModeValue( 'brand.500', 'white' );
  const textColorSecondary = 'gray.400';

  return (
    <Card {...rest} mb="20px" alignItems="center" p="20px" alignSelf="flex-start">
      <Flex alignItems={{ base: undefined, '2xl': 'center' }} h="100%" direction={{ base: 'column', '2xl': 'row' }}>
        <Dropzone
          w={{ base: '100%', '2xl': '268px' }}
          me="36px"
          minH="200px"
          h="100%"
          onFile={onFile}
          content={(
            <Box h="100%">
              <Icon as={MdUpload} w="80px" h="80px" color={brandColor} />
              <Flex justify="center" mx="auto" mb="12px">
                <Text fontSize="xl" fontWeight="700" color={brandColor}>
                  { t( 'certifiedPerson.upload.upload' ) }
                </Text>
              </Flex>
              <Text fontSize="sm" fontWeight="500" color="secondaryGray.500">
                { t( 'certifiedPerson.upload.onlyPng' ) }
              </Text>
            </Box>
          )}
        />
        <Flex alignSelf="flex-start" direction="column" pe="44px">
          <Text
            color={textColorPrimary}
            fontWeight="bold"
            textAlign="start"
            fontSize="2xl"
            mt={{ base: '20px', '2xl': '20px' }}
          >
            { t( 'certifiedPerson.upload.heading' ) }
          </Text>
          <Image
            fallback={(
              <Text
                color={textColorSecondary}
                fontSize="md"
                mt={{ base: '10px', '2xl': '10px' }}
                textAlign="start"
              >
                { t( 'certifiedPerson.upload.description' ) }
              </Text>
            )}
            mt={{ base: '20px', '2xl': '20px' }}
            maxW="100%"
            border="1px dashed"
            borderColor="gray.300"
            overflow="hidden"
            w="100%"
            borderRadius="10"
            objectFit="contain"
            src={stampUrl}
            alt="Stamp"
          />
        </Flex>
      </Flex>
    </Card>
  );

}

Upload.defaultProps = {
  used: 0,
  total: 0,
};
