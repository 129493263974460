import React, { useState, useMemo } from 'react';
// Chakra imports
import {
  Portal, Box, useColorModeValue, Center, Button,
} from '@chakra-ui/react';
import { Redirect, Route, Switch } from 'react-router-dom';
import routes from 'routes';
import Footer from '~/components/footer/FooterAdmin';
// Layout components
import Navbar from '~/components/navbar/NavbarAdmin';
import Sidebar from '~/components/sidebar/Sidebar';
import { SidebarContext } from '~/contexts/SidebarContext';

import { useGlobalData } from '~/core/hooks/useGlobalData';
import AuthFetch from '~/core/helpers/fetch';
import { API_BASE_URL } from '~/core/config';

export default function Dashboard( props: { [x: string]: any } ) {

  const { dispatch, state } = useGlobalData();
  const gradient = useColorModeValue( 'linear(to-r, cyan.700, purple.500)', 'linear(to-r, cyan.600, purple.400)' );
  const textColor = useColorModeValue( 'white', 'navy.700' );

  const getUser = async () => {

    const user = await AuthFetch.request( `${API_BASE_URL}/property/user`, {
      method: 'GET',
      credentials: 'include',
    } );

    dispatch( { type: 'SET_DATA', payload: { user } } );

  };

  React.useEffect( () => {

    getUser();

  }, [] );

  const { ...rest } = props;
  const [ toggleSidebar, setToggleSidebar ] = useState( false );

  const getRoute = () => window.location.pathname !== '/admin/full-screen-maps';

  const getActiveRoute = ( allRoutes: RoutesType[] ): string => {

    const activeRoute = 'MONTYO';
    for ( let i = 0; i < allRoutes.length; i++ ) {

      let { path } = allRoutes[i];
      const find = path.indexOf( ':' );
      if ( find > -1 ) {

        path = path.substring( 0, find - 1 );

      }
      if ( window.location.href.indexOf( allRoutes[i].layout + path ) !== -1 ) {

        return allRoutes[i].name;

      }

    }

    return activeRoute;

  };

  const getActiveNavbar = ( allRoutes: RoutesType[] ): boolean => {

    const activeNavbar = false;
    for ( let i = 0; i < allRoutes.length; i++ ) {

      if ( window.location.href.indexOf( allRoutes[i].layout + allRoutes[i].path ) !== -1 ) {

        return allRoutes[i].secondary;

      }

    }

    return activeNavbar;

  };

  const getRoutes = ( allRoutes: RoutesType[] ): any => allRoutes.map(
    ( route: RoutesType ) => {

      if ( route.layout === '/admin' ) {

        return (
          <Route
            path={route.layout + route.path}
            component={route.component}
            key={route.name}
          />
        );

      }

      return null;

    },
  );
  document.documentElement.dir = 'ltr';

  const cachedValue = useMemo(
    () => ( { toggleSidebar, setToggleSidebar } ),
    [ toggleSidebar, setToggleSidebar ],
  );

  return (
    <Box>
      {state.user
      && state.user.price > 0
      && ( !state.user.validUntil || new Date( state.user.validUntil ) < new Date() )
      && (
        <Box
          position="fixed"
          bottom={0}
          width="100%"
          zIndex={1000}
          bgGradient={gradient}
          color={textColor}
          py={2}
          px={2}
        >
          <Center>
            <Box textAlign="center" px={2}>Your license is invalid, you can do everything except export properties 💎</Box>
            <form action={`${API_BASE_URL}/payment/license/${state.user?.id}`} method="POST">
              <Button type="submit" borderRadius={6} colorScheme="teal" variant="solid" size="sm">
                {`Pay ${state.user.price} €`}
              </Button>
            </form>
          </Center>
        </Box>
      )}
      <SidebarContext.Provider
        value={cachedValue}
      >
        <Sidebar routes={routes().filter( ( item ) => !item.notInMenu )} display="none" {...rest} />
        <Box
          float="right"
          minHeight="100vh"
          height="100%"
          overflow="auto"
          position="relative"
          maxHeight="100%"
          w={{ base: '100%', xl: 'calc( 100% - 290px )' }}
          maxWidth={{ base: '100%', xl: 'calc( 100% - 290px )' }}
          transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
          transitionDuration=".2s, .2s, .35s"
          transitionProperty="top, bottom, width"
          transitionTimingFunction="linear, linear, ease"
        >
          <Portal>
            <Box>
              <Navbar
                brandText={getActiveRoute( routes() )}
                secondary={getActiveNavbar( routes() )}
                {...rest}
              />
            </Box>
          </Portal>

          {getRoute() ? (
            <Box mx="auto" p={{ base: '20px', md: '30px' }} pe="20px" minH="100vh" pt="50px">
              <Switch>
                {getRoutes( routes() )}
                <Redirect from="/" to="/admin/default" />
              </Switch>
            </Box>
          ) : null}
          <Box>
            <Footer />
          </Box>
        </Box>
      </SidebarContext.Provider>
    </Box>
  );

}
